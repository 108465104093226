import { createActionGroup, props } from '@ngrx/store';
import { DialerLeadCall } from 'src/app/shared/models/classes/call';

export const LeadCallActions = createActionGroup({
  source: 'LeadCall',
  events: {
    'Load LeadCall Live': props<{ agencyId: string, leadId: string, leadCallId: string }>(),
    'Load LeadCall Live Success': props<{ leadCall: DialerLeadCall }>(),
    'Load LeadCall Live Failure': props<{ error: any }>(),
    'Update LeadCall': props<{
      agencyId: string,
      leadId: string,
      leadCallId: string;
      leadCall: Partial<DialerLeadCall>;
    }>(),
    'Update LeadCall Success': props<{
      agencyId: string,
      leadId: string,
      leadCallId: string;
      leadCall: Partial<DialerLeadCall>;
    }>(),
    'Update LeadCall Failure': props<{ error: any }>(),
  },
});
