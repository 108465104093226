import { createFeature, createReducer, on } from '@ngrx/store';
import { DialerLeadCall } from 'src/app/shared/models';
import { LeadCallActions } from './lead-call.actions';

export const leadCallFeatureKey = 'leadCall';

export interface LeadCallState {
  leadCalls: DialerLeadCall[];
  leadCall: DialerLeadCall | null;
  loading: boolean;
  error: any;
}

export const initialState: LeadCallState = {
  leadCalls: [],
  leadCall: null,
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    LeadCallActions.loadLeadCallLive,
    (state): LeadCallState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadCallActions.loadLeadCallLiveSuccess,
    (state, { leadCall }): LeadCallState => ({
      ...state,
      leadCalls: [leadCall],
      leadCall: leadCall,
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadCallActions.loadLeadCallLiveFailure,
    (state, { error }): LeadCallState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  // on(
  //   LeadCallActions.addLeadCall,
  //   (state): LeadCallState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   }),
  // ),
  // on(
  //   LeadCallActions.addLeadCallSuccess,
  //   (state, { leadCall }): LeadCallState => ({
  //     ...state,

  //     leadCalls: [...state.leadCalls, leadCall],

  //     loading: false,

  //     error: null,
  //   }),
  // ),
  // on(
  //   LeadCallActions.addLeadCallFailure,
  //   (state, { error }): LeadCallState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   }),
  // ),
  // on(
  //   LeadCallActions.removeLeadCall,
  //   (state): LeadCallState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   }),
  // ),
  // on(
  //   LeadCallActions.removeLeadCallSuccess,
  //   (state, { leadCallId }): LeadCallState => ({
  //     ...state,
  //     leadCalls: state.leadCalls.filter((leadCall) => leadCall.id !== leadCallId),
  //     loading: false,
  //     error: null,
  //   }),
  // ),
  // on(
  //   LeadCallActions.removeLeadCallFailure,
  //   (state, { error }): LeadCallState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   }),
  // ),
  on(
    LeadCallActions.updateLeadCall,
    (state): LeadCallState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadCallActions.updateLeadCallSuccess,
    (state, { leadCall }): LeadCallState => ({
      ...state,
      leadCalls: state.leadCalls.map((item) =>
        // item.id === leadCall.id ? leadCall : item
        item.id === leadCall.id ? { ...item, ...leadCall } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadCallActions.updateLeadCallFailure,
    (state, { error }): LeadCallState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const leadCallFeature = createFeature({
  name: leadCallFeatureKey,
  reducer,
});
